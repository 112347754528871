import React, { useState } from 'react'
import HeaderText from '../components/text/HeaderText'
import PText from '../components/text/PText'
import MainText from '../components/text/MainText'
import Camdridge from '../components/ui/svg/Cambridge.svg';
import Cobis from '../components/ui/svg/Cobis.svg';
import Ukrlp from '../components/ui/svg/Ukrlp.svg';
import { useLocation, useParams } from 'react-router-dom';
import { FaSquareFacebook, FaLinkedin, FaSquareInstagram, FaSquareTwitter } from "react-icons/fa6";
import DesiredStartDateSection from '../components/ui/section/DesiredStartDateSection';
import InformationAboutStudentSection from '../components/ui/section/InformationAboutStudentSection';
import InformationAboutParentSection from '../components/ui/section/InformationAboutParentSection';
import StudyInformationSection from '../components/ui/section/StudyInformationSection';
import SubjectSelectionSection from '../components/ui/section/SubjectSelectionSection';
import SummarySection from '../components/ui/section/SummarySection';
import PaymentSection from '../components/ui/section/PaymentSection';
import { date, ValidationErrors } from "../utils/Interfaces";
import { Subject, DateObject, Package, ParentChooseData, GenderParentChooseData, ALevelProgram } from '../utils/Interfaces';
import { useTypedSelector } from '../hooks/useTypedSelector';
import CheckoutSectionWrapper from '../components/ui/section/CheckoutSectionWrapper';
import SubmitApplication from '../components/ui/section/SubmitApplication';
import SmallLightText from '../components/text/SmallLightText';
interface NewPageProps { }

const NewPage: React.FC<NewPageProps> = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const partner = query.get('partner');
  console.log(partner);
  
  const parentInformation = useTypedSelector((state: any) => state.parentInformation)
  //Study Information
  const [selectedPackage, setSelectedPackage] = useState<Package | ALevelProgram | null>(null);
  const [selectedSubjects, setSelectedSubjects] = useState<Subject[] | null>(null);
  const [date, setDate] = useState<DateObject>({

    day: 3,
    month: 9,
    year: 2024,
  });

  const [desiredDate, setDesiredDate] = useState<null | date>(null);
  const [startDate, setStartDate] = useState<null | string>(null);


  const [firstNameStudent, setFirstNameStudent] = useState('');
  const [middleNameStudent, setMiddleNameStudent] = useState('');
  const [lastNameStudent, setLastNameStudent] = useState('');
  const [emailStudent, setEmailStudent] = useState('');
  const [dateBirthStudent, setDateBirthStudent] = useState('');

  const [phoneNumberStudent, setPhoneNumberStudent] = useState<string>('44');
  const [addressLineStudent, setAddressLineStudent] = useState('');
  const [postalCodeStudent, setPostalCodeStudent] = useState('');
  const [countryStudent, setCountryStudent] = useState<string | number>('');
  const [cityStudent, setCityStudent] = useState('');
  const [parentChooseParent, setParentChooseParent] = useState<ParentChooseData>(parentInformation.parentChooseParent || ParentChooseData.Parent);
  const [genderChooseParent, setGenderChooseParent] = useState<GenderParentChooseData>(parentInformation.genderChooseParent || GenderParentChooseData.Male);
  const [firstNameParent, setFirstNameParent] = useState(parentInformation.firstNameParent || '');
  const [lastNameParent, setLastNameParent] = useState(parentInformation.lastNameParent || '');
  const [emailParent, setEmailParent] = useState(parentInformation.emailParent || '');
  const [phoneNumberParent, setPhoneNumberParent] = useState<string>(parentInformation.phoneNumberParent || '44');
  const [addressLineParent, setAddressLineParent] = useState(parentInformation.addressLineParent || '');
  const [postalCodeParent, setPostalCodeParent] = useState(parentInformation.postalCodeParent || '');
  const [countryParent, setCountryParent] = useState<string | number>(parentInformation.countryParent || '');
  const [cityParent, setCityParent] = useState(parentInformation.cityParent || '');


  const [priceWithCondition, setPriceWithCondition] = useState<number | null>(null);
  const [priceWithDiscount, setPriceWithDiscount] = useState<number | null>(null);

  const [isCheckoutOpen, setIsCheckoutOpen] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});


  return (
    <React.Fragment>
      <div className='hidden md:flex md:justify-between md:items-center md:container py-3'>
        <div><img src='/logo19231.webp' onClick={() => { window.open("https://www.iwsonlineschool.co.uk/", "_blank") }} className=' w-36 object-contain cursor-pointer' alt="logo" /></div>
      
        <div>
          <div onClick={() => { window.open("http://www.iwsonlineschool.co.uk/book-a-call/", "_blank") }} className='bg-blue text-center cursor-pointer px-4 py-2 rounded-md text-white font-[500] text-[14px]'>
            Book a call
          </div>
        </div>

      </div>
      <div className='w-full h-[2px] bg-textLight' />

      <div className='py-12'>
        <div className='container'>
          <HeaderText />
          <div className='pt-4 max-w-[800px]'>
            <PText text="Please check that all information is correct before submitting." />
          </div>

        </div>
        <div className='md:grid md:grid-cols-12 container'>
          <div className='md:col-span-7'>
            <div className='container'>

              <div className='pt-10'>
                <StudyInformationSection setDesiredDate={setDesiredDate} validationErrors={validationErrors} setValidationErrors={setValidationErrors} selectedSubjects={selectedSubjects} setSelectedSubjects={setSelectedSubjects} selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage} setStartDate={setStartDate} />
              </div>
              {
                selectedPackage && (
                  <div className='pt-10'>
                    <SubjectSelectionSection setValidationErrors={setValidationErrors} selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage} selectedSubjects={selectedSubjects} validationErrors={validationErrors} setSelectedSubjects={setSelectedSubjects} />
                  </div>

                )
              }

              <div className='pt-10'>
                <DesiredStartDateSection
                  validationErrors={validationErrors}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  desiredDate={desiredDate}
                  setDesiredDate={setDesiredDate}
                />


              </div>



              <div className='pt-10'>
                <InformationAboutStudentSection
                  emailStudent={emailStudent}
                  setEmailStudent={setEmailStudent}
                  dateBirthStudent={dateBirthStudent}
                  setDateBirthStudent={setDateBirthStudent}
                  setValidationErrors={setValidationErrors}
                  firstNameStudent={firstNameStudent}
                  setFirstNameStudent={setFirstNameStudent}
                  middleNameStudent={middleNameStudent}
                  setMiddleNameStudent={setMiddleNameStudent}
                  lastNameStudent={lastNameStudent}
                  setLastNameStudent={setLastNameStudent}
                  phoneNumberStudent={phoneNumberStudent}
                  setPhoneNumberStudent={setPhoneNumberStudent}
                  addressLineStudent={addressLineStudent}
                  setAddressLineStudent={setAddressLineStudent}
                  postalCodeStudent={postalCodeStudent}
                  setPostalCodeStudent={setPostalCodeStudent}
                  countryStudent={countryStudent}
                  setCountryStudent={setCountryStudent}
                  cityStudent={cityStudent}
                  setCityStudent={setCityStudent}
                  validationErrors={validationErrors}
                />

              </div>
              <div className='pt-10'>
                <InformationAboutParentSection
                  setValidationErrors={setValidationErrors}
                  parentChooseParent={parentChooseParent}
                  setParentChooseParent={setParentChooseParent}
                  genderChooseParent={genderChooseParent}
                  setGenderChooseParent={setGenderChooseParent}
                  firstNameParent={firstNameParent}
                  setFirstNameParent={setFirstNameParent}
                  lastNameParent={lastNameParent}
                  setLastNameParent={setLastNameParent}
                  emailParent={emailParent}
                  setEmailParent={setEmailParent}
                  phoneNumberParent={phoneNumberParent}
                  setPhoneNumberParent={setPhoneNumberParent}
                  addressLineParent={addressLineParent}
                  setAddressLineParent={setAddressLineParent}
                  postalCodeParent={postalCodeParent}
                  setPostalCodeParent={setPostalCodeParent}
                  countryParent={countryParent}
                  setCountryParent={setCountryParent}
                  cityParent={cityParent}
                  setCityParent={setCityParent}
                  validationErrors={validationErrors}
                />
              </div>
            </div>
          </div>

          <div className='md:col-span-5 md:justify-end md:flex'>
            <div className='pt-16'>
              <div className=' bg-inputBg md:bg-white w-full lg:min-w-[400px] md:max-w-[380px] md:border md:rounded-lg md:border-inputBorder'>
                <div className='px-5 py-6'>
                  <SummarySection startDate={startDate} desiredDate={desiredDate} selectedPackage={selectedPackage} selectedSubjects={selectedSubjects} />
                </div>
                <div className='px-5 py-6'>
                  <PaymentSection desiredDate={desiredDate} selectedPackage={selectedPackage} selectedSubjects={selectedSubjects} priceWithCondition={priceWithCondition} setPriceWithCondition={setPriceWithCondition} priceWithDiscount={priceWithDiscount} setPriceWithDiscount={setPriceWithDiscount} />
                  <div className='py-5'>
                    <div className='pt-1'>
                      <SmallLightText text={"If you have a voucher (promotion) code, please enter it on the checkout page."} />
                      <div className='py-10 px-8'>
                        <SubmitApplication
                          partner={partner}
                          selectedPackage={selectedPackage}
                          selectedSubjects={selectedSubjects}
                          date={date}
                          startDate={startDate}
                          desiredDate={desiredDate}
                          setSelectedPackage={setSelectedPackage}
                          firstNameStudent={firstNameStudent}
                          emailStudent={emailStudent}
                          dateBirthStudent={dateBirthStudent}
                          lastNameStudent={lastNameStudent}
                          phoneNumberStudent={phoneNumberStudent}
                          addressLineStudent={addressLineStudent}
                          postalCodeStudent={postalCodeStudent}
                          countryStudent={countryStudent}
                          cityStudent={cityStudent}
                          parentChooseParent={parentChooseParent}
                          genderChooseParent={genderChooseParent}
                          firstNameParent={firstNameParent}
                          lastNameParent={lastNameParent}
                          middleNameStudent={middleNameStudent}
                          emailParent={emailParent}
                          phoneNumberParent={phoneNumberParent}
                          addressLineParent={addressLineParent}
                          postalCodeParent={postalCodeParent}
                          countryParent={countryParent}
                          cityParent={cityParent}
                          validationErrors={validationErrors}
                          setValidationErrors={setValidationErrors}
                          priceWithCondition={priceWithCondition}
                          setPriceWithCondition={setPriceWithCondition}
                          priceWithDiscount={priceWithDiscount}
                          setPriceWithDiscount={setPriceWithDiscount}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className='pt-10 container'>
          <MainText text={"Accredited by"} />
          <div className='w-full mt-2 h-[2px] rounded-full bg-black' />
          <div className='flex justify-center'>
            <div className='pt-10 grid grid-cols-1 sm:grid-cols-3 justify-center items-center gap-10'>
              <img src={Camdridge} alt="" />
              <img src={Cobis} alt="" />
              <img src={Ukrlp} alt="" />
            </div>

          </div>
        </div>
      </div>
      <div className='hidden md:flex md:flex-col w-full bg-indigo-100'>

        <div className='flex justify-between w-full container py-5'>
          <div><img src='/logo19231.webp' onClick={() => { window.open("https://www.iwsonlineschool.co.uk/", "_blank") }} className=' w-48 object-contain cursor-pointer' alt="logo" /></div>

          <div>
            <div>
              {/* <p className='text-[16px] text-indigo-900 font-[600] tracking-tighter'>Join Our Community</p>
              <div className='flex justify-center items-center py-2'>
                <input type="text" className='px-3 max-w-48 min-w-48 w-48 h-10 max-h-10 min-h-10' placeholder='Enter your email' />
                <div className='bg-blue h-10 w-10 min-w-10 max-w-10 min-h-10 max-h-10 flex justify-center items-center text-white text-[14px] font-[400]'>GO</div>

              </div> */}
              <div className='pt-5'>
                <p className='text-[16px] text-indigo-900 font-[600] tracking-tighter'>Follow us on social media</p>
                <div className='flex gap-3 items-center py-2'>
                  <FaLinkedin className=' cursor-pointer' onClick={() => { window.open("https://www.linkedin.com/company/iwschoolonline/", "_blank") }} size={28} />
                  <FaSquareFacebook className=' cursor-pointer' onClick={() => { window.open("https://www.facebook.com/iwschoolonline", "_blank") }} size={28} />
                  <FaSquareTwitter className=' cursor-pointer' onClick={() => { window.open("https://twitter.com/iwsonlineschool", "_blank") }} size={28} />
                  <FaSquareInstagram className=' cursor-pointer' onClick={() => { window.open("https://www.instagram.com/iwsonlineschool/", "_blank") }} size={28} />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='container'>
          <div className='h-[2px] bg-textLight w-full rounded-full' />
          <div className='py-5 flex justify-between items-center'>
            <p onClick={() => { window.open("https://www.iwsonlineschool.co.uk/terms-and-conditions", "_blank") }} className='text-[16px] cursor-pointer text-indigo-900 font-[600] tracking-tighter'>Terms and Conditions</p>
            <p className='text-[16px] text-indigo-900 font-[600] tracking-tighter'>2024 IWS Online School. All rights reserved</p>
            <p onClick={() => { window.open("https://www.iwsonlineschool.co.uk/privacy-policy", "_blank") }} className='text-[16px] cursor-pointer text-indigo-900 font-[600] tracking-tighter'>Privacy Policy</p>
          </div>
        </div>
      </div>
    </React.Fragment>

  )
}

export default NewPage