export const Dates_Desired = [
  {
    'id': 1,
    "name": "1 week",
    "session": "(5 sessions)",
    "start_date": [
      {
        'id': 1,
        'name': 'July 29',
      },
      {
        'id': 2,
        'name': 'August 5',
      },
      {
        'id': 3,
        'name': 'August 12',
      },
      {
        'id': 4,
        'name': 'August 19',
      },
    ],
  },
  {
    'id': 2,
    "name": "2 week",
    "session": "(10 sessions)",
    "start_date": [
      {
        'id': 1,
        'name': 'July 29',
      },
      {
        'id': 2,
        'name': 'August 5',
      },
      {
        'id': 3,
        'name': 'August 12',
      },
    ],
  },
  {
    'id': 3,
    "name": "3 week",
    "session": "(15 sessions)",
    "start_date": [
      {
        'id': 1,
        'name': 'July 29',
      },
      {
        'id': 2,
        'name': 'August 5',
      },
    ],
  },
  {
    'id': 4,
    "name": "4 week",
    "session": "(20 sessions)",
    "start_date": [
      {
        'id': 1,
        'name': 'July 29',
      },
    ],
  },

]
