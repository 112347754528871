import React, { useState } from 'react'
import PText from '../../text/PText'
import PRedText from '../../text/PRedText';
import { date, ValidationErrors } from '../../../utils/Interfaces';
import FormLayout from '../../form/FormLayout';
import { Dates_Desired } from '../../../constants/dates';
import StandartText from '../../text/StandartText';
import SmallLightText from '../../text/SmallLightText';

interface DesiredStartDateSectionProps {

  validationErrors?: ValidationErrors

  desiredDate: null | date;
  startDate: string | null;
  setStartDate: React.Dispatch<React.SetStateAction<string | null>>;
  setDesiredDate: React.Dispatch<React.SetStateAction<null | date>>;
}



const DesiredStartDateSection: React.FC<DesiredStartDateSectionProps> = ({
  // date,
  //  setDate, 
  startDate,
  setStartDate,
  desiredDate,
  setDesiredDate,
  validationErrors }) => {
  const today = new Date();

  // useEffect(() => {
  //   const newDaysInMonth = getDaysInMonth(date.month, date.year);
  //   const days = Array.from({ length: newDaysInMonth }, (_, i) => i + 1);
  //   // Filter out past days if the selected year and month are the current ones
  //   const validDays = date.year === today.getFullYear() && date.month === today.getMonth() + 1
  //     ? days.filter(day => day >= today.getDate())
  //     : days;

  //   setDaysInMonth(validDays);

  //   // Reset day if it's no longer valid
  //   if (!validDays.includes(date.day)) {
  //     setDate((currentDate) => ({ ...currentDate, day: validDays[0] }));
  //   }
  // }, [date.month, date.year]);


  const handleDateSelection = (desired_date: date) => {
    if (desired_date !== desiredDate) {
      setDesiredDate(desired_date);
      setStartDate(desired_date.start_date[0].name)
    }
  }

  const handleStartDate = (start_date: string) => {
    setStartDate(start_date)
  }
  return (

    <div className='flex flex-col gap-10'>
      <div>
        <PText text="Select your desired dates:" required={true} />
        {
          validationErrors && validationErrors.date && (
            <PRedText text={validationErrors.date} />
          )
        }

        <div className='pt-1 xs:pt-3 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 gap-3'>
          {Dates_Desired.map((des_date => (
            <FormLayout
              onClick={() => handleDateSelection(des_date)}
              selected={des_date.name === desiredDate?.name}
            >
              <div className='flex items-center flex-1 justify-center flex-col'>
                <StandartText text={des_date.name} />
                <SmallLightText text={des_date.session} />
              </div>
            </FormLayout>
       
          )))}

        </div>
      </div>


      {
        desiredDate !== null && (
          <>
            <div>
              <PText text="Select your desired starting dates:" required={true} />

              <div className='pt-1 xs:pt-3 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 gap-3'>
                {desiredDate.start_date.map((start_date) => (
                  <FormLayout
                    onClick={() => handleStartDate(start_date.name)}
                    selected={start_date.name === startDate}
                  >
                    <div className='flex items-center flex-1 justify-center flex-col'>
                      <StandartText text={start_date.name} />
                    </div>
                  </FormLayout>
                ))}
              </div>

            </div>


          </>
        )
      }




    </div>
  )
}

export default DesiredStartDateSection