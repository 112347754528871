import React, { useState } from 'react';
import * as Yup from 'yup';
import { useActions } from '../../../hooks/useActions';
import axios from 'axios';
import { ALevelProgram, date, DateObject, GenderParentChooseData, Package, ParentChooseData, Subject, ValidationErrors } from '../../../utils/Interfaces';

interface SubmitApplicationProps {
  validationErrors?: ValidationErrors;
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  selectedPackage: Package | ALevelProgram | null;
  selectedSubjects: Subject[] | null;
  date: DateObject;
  partner: string | null;
  desiredDate: null | date;
  startDate: string | null;
  emailStudent: string;
  dateBirthStudent: string;
  firstNameStudent: string;
  middleNameStudent: string;
  lastNameStudent: string;
  phoneNumberStudent: string;
  addressLineStudent: string;
  postalCodeStudent: string;
  countryStudent: string | number;
  cityStudent: string;
  parentChooseParent: ParentChooseData;
  genderChooseParent: GenderParentChooseData;
  firstNameParent: string;
  lastNameParent: string;
  emailParent: string;
  phoneNumberParent: string;
  addressLineParent: string;
  postalCodeParent: string;
  countryParent: string | number;
  cityParent: string;
  priceWithCondition: number | null;
  setPriceWithCondition: React.Dispatch<React.SetStateAction<number | null>>;
  priceWithDiscount: number | null;

  setPriceWithDiscount: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedPackage: React.Dispatch<React.SetStateAction<Package | ALevelProgram | null>>;
}

const SubmitApplication: React.FC<SubmitApplicationProps> = ({
  validationErrors,
  selectedPackage,
  partner,
  selectedSubjects,
  date,
  desiredDate,
  startDate,
  firstNameStudent,
  emailStudent,
  dateBirthStudent,
  lastNameStudent,
  middleNameStudent,
  phoneNumberStudent,
  addressLineStudent,
  postalCodeStudent,
  countryStudent,
  cityStudent,
  parentChooseParent,
  genderChooseParent,
  firstNameParent,
  lastNameParent,
  emailParent,
  phoneNumberParent,
  addressLineParent,
  postalCodeParent,
  countryParent,
  cityParent,
  setValidationErrors
}) => {

  const { addParentInformation } = useActions();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false)
  const validationSchema = Yup.object().shape({
    selectedPackage: Yup.mixed().nullable().required('A package selection is required'),
    selectedSubjects: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      })
    ).nullable().required("At least" + selectedPackage?.min_select + " subject(s) must be selected"),
    date: Yup.object().shape({
      day: Yup.number().required(),
      month: Yup.number().required(),
      year: Yup.number().required(),
    }).required('Date is required'),
    firstNameStudent: Yup.string().required('First name is required'),
    emailStudent: Yup.string().email('Invalid email format').required('Email is required'),
    dateBirthStudent: Yup.string().required('Date of Birth is required'),
    lastNameStudent: Yup.string().required('Last name is required'),
    phoneNumberStudent: Yup.string().required('Phone number is required'),
    countryStudent: Yup.string().trim().required('Country is required').test('is-not-empty', 'Country cannot be empty', (value) => value !== " "),
    parentChooseParent: Yup.mixed().oneOf(Object.values(ParentChooseData)).required('Parent choice is required'),
    genderChooseParent: Yup.mixed().oneOf(Object.values(GenderParentChooseData)).required('Gender choice is required'),
    firstNameParent: Yup.string().required('First name is required'),
    lastNameParent: Yup.string().required('Last name is required'),
    emailParent: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumberParent: Yup.string().required('Phone number is required'),
    addressLineParent: Yup.string().required('Address line is required'),
    postalCodeParent: Yup.string().required('Postal code is required'),
    countryParent: Yup.string().trim().required('Country is required').test('is-not-empty', 'Country cannot be empty', (value) => value !== " "),
    cityParent: Yup.string().required('City is required'),
  });


  const handlePayment = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log(partner);

    const data = {
      student: {
        firstName: firstNameStudent,
        lastName: lastNameStudent,
        email: emailStudent,
        dob: dateBirthStudent,
        phone: phoneNumberStudent,
        country: countryStudent
      },
      parent: {
        guardian: parentChooseParent,
        gender: genderChooseParent,
        firstName: firstNameParent,
        lastName: lastNameParent,
        email: emailParent,
        phone: phoneNumberParent,
        address: addressLineParent,
        postalCode: postalCodeParent,
        country: countryParent,
        city: cityParent
      },
      study: {
        ageGroup: selectedPackage?.name,
        programmes: selectedSubjects,
        desiredDate: desiredDate?.name + '/' + desiredDate?.session,
        desiredStartDate: startDate
      },
      partner: partner,
      totalPrice: selectedPackage && (calculateAnnualPaymentWithCondition(selectedSubjects, selectedPackage)),

    }

    setLoading(true);

    try {
      await validationSchema.validate({
        selectedPackage, selectedSubjects, date, firstNameStudent, lastNameStudent, phoneNumberStudent, countryStudent, parentChooseParent, genderChooseParent, firstNameParent, emailStudent, dateBirthStudent, lastNameParent, emailParent, phoneNumberParent, addressLineParent, postalCodeParent, countryParent, cityParent
      }, { abortEarly: false });

      const json = {
        selectedPackage, selectedSubjects, date, firstNameStudent, lastNameStudent, phoneNumberStudent, addressLineStudent, postalCodeStudent, countryStudent, cityStudent, parentChooseParent, genderChooseParent, firstNameParent, lastNameParent, emailParent, phoneNumberParent, addressLineParent, postalCodeParent, countryParent, cityParent
      };
      addParentInformation(json);

      try {
        const response = await axios.post("https://api.main.iwsonlineschool.co.uk/create-checkout-session", {
        // const response = await axios.post('https://api.summer-enrol.iwsonlineschool.co.uk/create-checkout-session', {
          // const response = await axios.post('http://192.168.1.101:3500/create-checkout-session', {
          // const response = await axios.post('https://webhook-test.com/4c632ebe51cbe263d0747bff3def9d00', {
          data: data,
        });
        window.location.href = response.data.url;

      } catch (error) {
        console.error('Payment Error:', error);
        setError('Payment processing failed. Please try again.');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = err.inner.reduce((acc, error) => {
          if (typeof error.path === 'string') {
            return {
              ...acc,
              [error.path]: error.message
            };
          }
          return acc;
        }, {});
        setValidationErrors(errors);
      } else {
        setError('Validation failed. Please check your input.');
      }
    } finally {
      setLoading(false); // Set loading to false when everything is complete
    }
  };

  function calculateAnnualPaymentWithCondition(selectedSubjects: Subject[] | null, programOrPackage: Package | ALevelProgram) {
    let sum = 0;
    if ('condition' in programOrPackage && programOrPackage.condition) {
      if (selectedSubjects) {
        selectedSubjects.forEach(sub => {
          sum += sub.price;
        });

        // Check if programOrPackage is a Package by looking for the 'condition' property
        // Ensure selectedSubjects length meets or exceeds the condition for a discount
        if (selectedSubjects.length >= programOrPackage.condition.selected && selectedSubjects.length < 6) {
          sum *= (1 - programOrPackage.condition.discount / 100); // Apply discount
        }
        if (selectedSubjects.length >= 6) {
          sum = 0
          if (selectedPackage?.name === "Primary School") {
            selectedSubjects.slice(0, 5).forEach((sub, index) => {
              sum += sub.price;
              // You can perform other operations here as needed
            });
            sum *= (1 - programOrPackage.condition.discount / 100); // Apply discount
            selectedSubjects.slice(5).forEach((sub, index) => {
              sum += 840;
              // You can perform other operations here as needed
            });
            // sum *= (1 - 18.1818 / 100); // Apply discount
          }


          if (selectedPackage?.name === "Secondary School") {
            selectedSubjects.slice(0, 5).forEach((sub, index) => {
              sum += sub.price;
              // You can perform other operations here as needed
            });
            sum *= (1 - programOrPackage.condition.discount / 100); // Apply discount
            selectedSubjects.slice(5).forEach((sub, index) => {
              sum += 1008;
            });
          }

          if (selectedPackage?.name === "I/GCSE School") {
            selectedSubjects.slice(0, 5).forEach((sub, index) => {
              sum += sub.price;
              // You can perform other operations here as needed
            });
            sum *= (1 - programOrPackage.condition.discount / 100); // Apply discount
            selectedSubjects.slice(5).forEach((sub, index) => {
              sum += 604.8;
            });
          }

        }
      }
      // You can add an 'else if' block here to handle ALevelProgram specific logic if needed
    }
    if ('price_condition' in programOrPackage && programOrPackage.price_condition) {
      if (selectedSubjects) {
        const numberOfSubjects = selectedSubjects.length;
        const priceConditionItem = programOrPackage.price_condition.find(item => item[numberOfSubjects]);

        if (priceConditionItem) {
          sum = priceConditionItem[numberOfSubjects] ?? 0;
        }
      }
    }
    if (desiredDate?.id !== undefined) {
      return sum * desiredDate?.id;
    } else {
      return 0
    }
  }

  return (
    <>
      {
        !loading && (
          <div onClick={handlePayment} className='bg-blue hover:bg-purple-600 cursor-pointer w-full rounded-lg'>
            <div className='text-center py-4 px-6 text-white font-bold text-[20px] tracking-wider'>Go To Payment</div>
          </div>

        )
      }
    </>
  )
}

export default SubmitApplication