export const Programms = [
  {
    "name": "For ages 7-10",
    "age": "For ages 7-10",
    "discount": 0,
    "min_select": 1,
    "selected": 1,
    "condition": {
      "selected": 5,
      "discount": 0
    },
    "package": [
      {
        'id': 1,
        'name': 'English for ages 7-10',
        'price': 75,
        'exist': true
      },
      {
        'id': 2,
        'name': 'Mathematics for ages 7-10',
        'price': 75,
        'exist': false
      },
      {
        'id': 3,
        'name': 'Exam Prep for ages 7-10',
        'price': 75,
        'exist': false
      },
      {
        'id': 4,
        'name': 'School Preparation for ages 7-10',
        'price': 75,
        'exist': false
      },
    ],
  },
  {
    "name": "For ages 11-14",
    "age": "For ages 11-14",
    "discount": 0,
    "min_select": 1,
    "selected": 1,
    "condition": {
      "selected": 5,
      "discount": 0
    },
    "package": [
      {
        'id': 1,
        'name': 'English for ages 11-14',
        'price': 75,
        'exist': true
      },
      {
        'id': 2,
        'name': 'Mathematics for ages 11-14',
        'price': 75,
        'exist': false
      },
      {
        'id': 3,
        'name': 'Science for ages 11-14',
        'price': 75,
        'exist': false
      },
      {
        'id': 4,
        'name': 'Literacy Boost for ages 11-14',
        'price': 75,
        'exist': false
      },
      {
        'id': 5,
        'name': 'Numeracy Boost for ages 11-14',
        'price': 75,
        'exist': false
      },
    ],
  },
  {
    "name": "For ages 14-16",
    "age": "For ages 14-16",
    "discount": 0,
    "min_select": 1,
    "selected": 1,
    "condition": {
      "selected": 5,
      "discount": 0
    },
    "package": [
      {
        'id': 1,
        'name': 'IGCSE English for ages 14-16',
        'price': 75,
        'exist': true
      },
      {
        'id': 2,
        'name': 'IGCSE Mathematics for ages 14-16',
        'price': 75,
        'exist': false
      },
      {
        'id': 3,
        'name': 'IGCSE Science for ages 14-16',
        'price': 75,
        'exist': false
      },
      {
        'id': 4,
        'name': 'IGCSE English for ages 14-16',
        'price': 75,
        'exist': false
      },
      {
        'id': 5,
        'name': 'IGCSE Mathematics for ages 14-16',
        'price': 75,
        'exist': false
      },
      {
        'id': 6,
        'name': 'IGCSE Science for ages 14-16',
        'price': 75,
        'exist': false
      },
      {
        'id': 7,
        'name': 'Computer Science for ages 14-16',
        'price': 75,
        'exist': false
      },
    ],
  },

  {
    "name": "For ages 16 +",
    "age": "For ages 16 and above",
    "discount": 0,
    "min_select": 1,
    "selected": 1,
    "condition": {
      "selected": 5,
      "discount": 0
    },
    "package": [
      {
        'id': 1,
        'name': 'Preparation for A level study',
        'price': 75,
        'exist': true
      },
      {
        'id': 2,
        'name': 'IELTS Preparation Course',
        'price': 75,
        'exist': false
      },
      {
        'id': 3,
        'name': 'A-Level Physics Year 12 Revision',
        'price': 75,
        'exist': false
      },
      {
        'id': 4,
        'name': 'A Level Mathematics Year 12 Revision',
        'price': 75,
        'exist': false
      },
      {
        'id': 5,
        'name': 'A Level Computer Science Year 12 Revision',
        'price': 75,
        'exist': false
      },
    ],
  },


]

export const ALevelProgramm = {
  "name": "A Level",
  "age": "Ages between 16-18",
  "price_condition": [
    { 1: 1500 },
    { 2: 3000 },
    { 3: 4500 },
    { 4: 6000 },
    { 5: 7500 },
    { 6: 8288.28 },
    { 7: 9669.66 },
    { 8: 11051.04 },
    { 9: 12432.42 },
    { 10: 13813.8 },
    { 11: 15195.18 },
    { 12: 16576.56 },
    { 13: 17957.94 },
    { 14: 19339.32 },
    { 15: 20720.7 },
    { 16: 22102.08 },
    { 17: 23483.46 },
  ],
  "discount": 0,
  "min_select": 1,
  "selected": 3,
  "package": [
    {
      'id': 1,
      'name': 'Biology',
      'price': 2750,
      'exist': true
    },
    {
      'id': 2,
      'name': 'Business',
      'price': 2750,
      'exist': true
    },
    {
      'id': 3,
      'name': 'Chemistry',
      'price': 2750,
      'exist': true
    },
    {
      'id': 4,
      'name': 'Computer Science',
      'price': 2750,
      'exist': false
    },
    {
      'id': 5,
      'name': 'Economics',
      'price': 2750,
      'exist': false
    },
    {
      'id': 6,
      'name': 'English - Literature',
      'price': 2750,
      'exist': false
    },
    {
      'id': 7,
      'name': 'English Language',
      'price': 2750,
      'exist': false
    },
    {
      'id': 8,
      'name': 'Geography',
      'price': 2750,
      'exist': false
    },
    {
      'id': 9,
      'name': 'Global Perspectives & Research',
      'price': 2750,
      'exist': false
    },
    {
      'id': 10,
      'name': 'History',
      'price': 2750,
      'exist': false
    },
    {
      'id': 11,
      'name': 'Law',
      'price': 2750,
      'exist': false
    },
    {
      'id': 12,
      'name': 'Mathematics',
      'price': 2750,
      'exist': false
    },
    {
      'id': 13,
      'name': 'Mathematics - Further',
      'price': 2750,
      'exist': false
    },
    {
      'id': 14,
      'name': 'Media Studies',
      'price': 2750,
      'exist': false
    },
    {
      'id': 15,
      'name': 'Physics',
      'price': 2750,
      'exist': false
    },
    {
      'id': 16,
      'name': 'Psychology',
      'price': 2750,
      'exist': false
    },
    {
      'id': 17,
      'name': 'Sociology',
      'price': 2750,
      'exist': false
    },
  ],
}